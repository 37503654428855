import React from 'react'
import styled, { keyframes } from 'styled-components'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import ProductHuntBadge from '../components/ProductHuntBadge'
import DefaultLink from '../components/DefaultLink'
import Layout from '../components/Layout'
import device from '../lib/mediaQueries'
import * as colors from '../lib/colors'
import Instances from '../images/lineage/instances.svg'
import Dependencies from '../images/lineage/dependencies.svg'

const moveBlue = keyframes`
  from {
    transform: none;
  }

  to {
    transform: translate3d(-200px, 200px, 0);
  }
`
const moveRed = keyframes`
  from {
    transform: none;
  }

  to {
    transform: translate3d(-180px, -300px, 0);
  }
`
const moveYellow = keyframes`
  from {
    transform: none;
  }

  to {
    transform: translate3d(0, 600px, 0);
  }
`

const DifferenceBase = styled.div`
  top: 5vh;
  right: -300px;
  width: 350px;
  height: 350px;
  position: absolute;
  border-radius: 50%;
  mix-blend-mode: difference;
  pointer-events: none;
  display: block;
  opacity: 0.7;

  ${device.tablet} {
    width: 550px;
    height: 550px;
    opacity: 1;
  }
`

const DifferenceBlue = styled(DifferenceBase)`
  background-color: #00a2f6;
  animation: ${moveBlue} 120s linear alternate infinite;
`
const DifferenceRed = styled(DifferenceBase)`
  background-color: #e1203e;
  right: -200px;
  top: 60vh;
  animation: ${moveRed} 40s linear alternate infinite;
`
const DifferenceYellow = styled(DifferenceBase)`
  background-color: #ffcc15;
  left: -100px;
  top: -10vh;
  width: 150px;
  height: 150px;
  animation: ${moveYellow} 110s linear alternate infinite;

  ${device.tablet} {
    width: 300px;
    height: 300px;
    left: -200px;
  }
`

const HeroContainer = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;

  ${device.laptop} {
    flex-direction: row;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  z-index: 100;
  margin-bottom: 10px;
  pointer-events: none;
  border-radius: 4px;
  overflow: hidden;
  margin-top: -16px;

  ${device.laptop} {
    width: 45%;
    margin-bottom: 0px;
    margin-top: 0px;
  }
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;

  ${device.laptop} {
    position: inherit;
    width: 55%;
    max-width: 55%;
    margin-bottom: 0px;
  }
`
const TitleCenterContainer = styled.div`
  max-width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${device.laptop} {
    max-width: 70%;
  }
`
const Title = styled.h1`
  color: ${(props) => props.theme.main};
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: 35px;
`
const Description = styled.span`
  color: ${(props) => props.theme.highlight};
`

const Name = styled.span`
  color: ${(props) => props.theme.highlight};
  font-weight: bold;
  text-decoration: none;
  font-size: 18px;
  align-self: flex-start;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 32px;

  ${device.tablet} {
    flex-direction: row;
  }
`

const InstallButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 43px;
  margin-top: 8px;
  background-color: transparent;
  color: ${(props) => props.theme.main};
  border: 1px solid white;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.15s ease-out;
  user-select: none;

  ${device.tablet} {
    margin-left: 16px;
    margin-top: 0px;
  }

  &:hover {
    background-color: ${(props) => props.theme.main};
    color: ${(props) => props.theme.background};
  }
`

const AllFeaturesContainer = styled.section`
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  padding-top: 16px;
  cursor: default;

  ${device.tablet} {
    flex-direction: row;
  }
`

const FeatureContainer = styled.div`
  position: relative;
  display: flex;
  width: 80%;

  margin-bottom: 64px;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
  transition: color 0.2s ease-in-out;

  ${device.tablet} {
    max-width: 400px;
    width: 35%;

    color: rgba(255, 255, 255, 0.8);
  }

  &:hover {
    color: white;
  }
`

const IconImage = styled.div`
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  margin-bottom: 16px;
  display: block;
  background-image: url(${(props) => props.image});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  ${device.tablet} {
    width: 130px;
    height: 130px;
    margin-right: 0px;

    flex-direction: column;
  }
`
const ExplanationContainer = styled.section`
  padding: 64px 8%;
  margin-top: 40px;
  background-color: #ffcc15;

  text-align: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;

  color: ${(props) => props.theme.background};

  ${device.tablet} {
    padding: 90px 25%;
    margin-top: 60px;
    font-size: 22px;
  }
`

const Link = styled(DefaultLink)`
  color: ${(props) => props.theme.background};

  &:hover {
    color: #ffcc15;

    &::after {
      background-color: ${(props) => props.theme.background};
    }
  }

  &::after {
    background-color: ${(props) => props.theme.background};
  }
`

const ProjectsPage = ({ data }) => {
  const scheme = colors.lineage
  return (
    <Layout
      theme={scheme}
      absoluteTitle="Lineage for Figma"
      description="A plugin to visualize your component and style dependencies"
      image="/images/lineage-og.png"
      squareHidden
      patternHidden
    >
      <DifferenceBlue />
      <DifferenceRed />
      <DifferenceYellow />
      <HeroContainer>
        <TitleContainer>
          <TitleCenterContainer>
            <Name href="https://www.figma.com/community/plugin/869656614742356159/Lineage">
              Lineage
            </Name>
            <Title>
              Visualize your component and style dependencies on Figma.
            </Title>
            <Description>
              Lineage helps you understand how your design system is being used
              and how every change impacts your project.
            </Description>
            <ButtonContainer>
              <ProductHuntBadge
                url="https://www.producthunt.com/posts/lineage-for-figma"
                postId={238071}
                alt="Lineage for Figma - A plugin to visualize your component and style dependencies"
              />
              <InstallButton href="https://www.figma.com/community/plugin/869656614742356159/Lineage">
                Install on Figma
              </InstallButton>
            </ButtonContainer>
          </TitleCenterContainer>
        </TitleContainer>
        <ImageContainer>
          <Img fluid={data.hero.childImageSharp.fluid} />
        </ImageContainer>
      </HeroContainer>
      <AllFeaturesContainer>
        <FeatureContainer>
          <IconImage image={Dependencies} />
          <div>
            Find all the components and styles a design object depends on.
            Understand how the elements of your design system interact with each
            other.
          </div>
        </FeatureContainer>
        <FeatureContainer>
          <IconImage image={Instances} />
          <div>
            Find every instance of a component or style to understand how a
            individual changes affect your whole project.
          </div>
        </FeatureContainer>
      </AllFeaturesContainer>
      <ExplanationContainer>
        I'd love to know how you are using Lineage and what could be improved,
        reach me out on{' '}
        <Link href="https://twitter.com/lalomts" alt="Twitter">
          Twitter
        </Link>{' '}
        to share your thoughts!
      </ExplanationContainer>
    </Layout>
  )
}

ProjectsPage.propTypes = {
  data: PropTypes.shape,
}

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "lineage/lineage-hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ProjectsPage

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const LinkContainer = styled.a`
  opacity: 1;
  align-self: center;
  padding: 0;
  width: 220px;
  height: 50px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  user-select: none;
`

const PHBadge = ({ url, postId, alt }) => (
  <LinkContainer href={url} target="_blank">
    <img
      src={`https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=${postId}&theme=dark`}
      alt={`${alt} | Product Hunt`}
      style={{ width: 220, height: 50 }}
    />
  </LinkContainer>
)

PHBadge.propTypes = {
  url: PropTypes.string,
  postId: PropTypes.number,
  alt: PropTypes.string,
}

export default PHBadge
